import SharePostModal from "@/post/share/SharePostModal"
import { SharePostButtonFragment$key } from "@/post/share/__generated__/SharePostButtonFragment.graphql"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { isFuture } from "date-fns"
import { useCallback } from "react"
import { graphql, useFragment } from "react-relay"

interface SharePostButtonProps extends OverridableDiscoButtonProps, TestIDProps {
  postKey: SharePostButtonFragment$key
}

function SharePostButton(props: SharePostButtonProps) {
  const { postKey, children, ...rest } = props

  const post = useFragment<SharePostButtonFragment$key>(
    graphql`
      fragment SharePostButtonFragment on Post {
        id
        releasedAt
        ...usePermissionsFragment
      }
    `,
    postKey
  )

  const permissions = usePermissions(post)

  const ModalComponent = useCallback(
    ({ isOpen, onClose }) => {
      if (!isOpen) return null
      return <SharePostModal postId={post.id} onClose={onClose} />
    },
    [post.id]
  )

  // No sharing scheduled posts
  const isScheduled = Boolean(post.releasedAt) && isFuture(new Date(post.releasedAt!))
  if (isScheduled) return null

  // Must have permissions
  if (!permissions.has("post.share")) return null

  return (
    <OverridableDiscoButton modal={ModalComponent} leftIcon={"send"} {...rest}>
      {children || "Share"}
    </OverridableDiscoButton>
  )
}

export default SharePostButton
