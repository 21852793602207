/**
 * @generated SignedSource<<f37f1407066ba95e5ca3f6394d23b689>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductAppVisibility = "all" | "membership" | "admin" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AIFeedEmptyStateFragment$data = {
  readonly id: string;
  readonly app: {
    readonly id: string;
    readonly visibility: ProductAppVisibility;
    readonly customAppDescription: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"FeedAppSettingsButtonFragment">;
  };
  readonly " $fragmentType": "AIFeedEmptyStateFragment";
};
export type AIFeedEmptyStateFragment$key = {
  readonly " $data"?: AIFeedEmptyStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AIFeedEmptyStateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AIFeedEmptyStateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visibility",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppDescription",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedAppSettingsButtonFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Feed",
  "abstractKey": null
};
})();

(node as any).hash = "3f03cdb05c6350ff688aea4a778b9c04";

export default node;
