import Badge from "@/admin/experiences/badges/Badge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import {
  FeedSelectorDropdownQuery,
  ProductStatus,
} from "@/post/__generated__/FeedSelectorDropdownQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoInput, DiscoText } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { Popover } from "@material-ui/core"
import { useRef, useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

export type FeedSelection = {
  id: GlobalID
  name: string
  viewerPermissions?: readonly string[] | null
  product?: {
    id: string
    name: string
    slug: string
    status: ProductStatus
    startDate: string | null
    waitingRoomEndsAt: string | null
  } | null
}

interface Props {
  setFeedSelection: (Feed: FeedSelection) => void
  selectedFeed: FeedSelection
  children: OverridableDiscoButtonChildren
}
function FeedSelectorDropdown({ setFeedSelection, selectedFeed, children }: Props) {
  const ref = useRef<HTMLButtonElement | null>(null)
  const activeOrganization = useActiveOrganization()!
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [search, setSearch] = useState("")
  const classes = useStyles()
  const labels = useLabels()

  const { organization } = useLazyLoadQuery<FeedSelectorDropdownQuery>(
    graphql`
      query FeedSelectorDropdownQuery($id: ID!, $first: Int) {
        organization: node(id: $id) {
          ... on Organization {
            feeds(first: $first, canPost: true, excludeProductLevel: true) {
              edges {
                node {
                  id
                  name
                  viewerPermissions
                  app {
                    badge {
                      ...BadgeFragment
                    }
                    ...useRenderProductAppItemFragment @relay(mask: false)
                    product {
                      slug
                    }
                  }
                }
              }
            }
            products(type: "course") {
              edges {
                node {
                  id
                  name
                  status
                  slug
                  startDate
                  waitingRoomEndsAt
                  badge {
                    ...BadgeFragment
                  }
                  feeds(first: $first, canPost: true)
                    @connection(key: "ProductFeedSelectorDropdown_feeds") {
                    edges {
                      node {
                        id
                        name
                        viewerPermissions
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    },
    {
      fetchPolicy: "network-only",
    }
  )

  const products = Relay.connectionToArray(organization?.products)
  const totalProductFeeds = products.reduce((total, product) => {
    const productFeeds = Relay.connectionToArray(product.feeds).filter(filterBySearch)
    return total + productFeeds.length
  }, 0)

  const organizationFeeds = Relay.connectionToArray(organization?.feeds).filter(
    filterBySearch
  )

  return (
    <div data-testid={"FeedSelectorDropdown"}>
      <OverridableDiscoButton
        ref={ref}
        onClick={(e) => setAnchorEl(e.currentTarget as HTMLButtonElement)}
      >
        {children}
      </OverridableDiscoButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ classes: { root: classes.popoverPaper } }}
      >
        {/* Search Bar */}
        <DiscoInput
          data-testid={"FeedSelectorDropdown.Search"}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder={"Search"}
          fullWidth
        />

        {/* Community Feeds */}
        {organizationFeeds.length > 0 && (
          <>
            <DiscoText
              variant={"body-xs-600-uppercase"}
              display={"block"}
              marginTop={2}
              marginBottom={1}
            >
              {`Community`}
            </DiscoText>
            <div data-testid={"FeedSelectorDropdown.CommunityFeeds"}>
              {organizationFeeds.map((feed) => {
                return (
                  <div key={feed.id}>
                    <div className={classes.feedGroupHeader}>
                      <DiscoDropdownItem
                        key={feed.id}
                        className={classes.listItem}
                        onClick={() => {
                          setFeedSelection({
                            id: feed.id,
                            name: feed.name,
                            viewerPermissions: feed.viewerPermissions,
                          })
                          handleClose()
                        }}
                        title={feed.name}
                        icon={
                          feed.app.badge && <Badge badgeKey={feed.app.badge} size={24} />
                        }
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}

        {/* Product Feeds */}
        {totalProductFeeds > 0 && (
          <>
            <DiscoText
              variant={"body-xs-600-uppercase"}
              display={"block"}
              marginTop={2}
              marginBottom={1}
            >
              {`${labels.experience.plural}`}
            </DiscoText>
            <div data-testid={"FeedSelectorDropdown.ProductFeeds"}>
              {products.map((product) => {
                const productFeeds = Relay.connectionToArray(product.feeds).filter(
                  filterBySearch
                )
                if (!productFeeds.length) return null
                return (
                  <div key={product.id}>
                    <div className={classes.feedGroupHeader}>
                      {product.badge && <Badge badgeKey={product.badge} size={24} />}
                      <DiscoText color={"text.secondary"} variant={"body-sm"}>
                        {product.name}
                      </DiscoText>
                    </div>
                    <div className={classes.feedsContainer}>
                      {productFeeds.map((feed) => (
                        <DiscoDropdownItem
                          key={feed.id}
                          className={classes.listItem}
                          selected={selectedFeed.id === feed.id}
                          onClick={() => {
                            setFeedSelection({
                              id: feed.id,
                              name: feed.name,
                              viewerPermissions: feed.viewerPermissions,
                              product: {
                                id: product.id,
                                name: product.name,
                                status: product.status,
                                slug: product.slug,
                                waitingRoomEndsAt: product.waitingRoomEndsAt,
                                startDate: product.startDate,
                              },
                            })
                            handleClose()
                          }}
                          title={feed.name}
                        />
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
        {totalProductFeeds <= 0 && !organizationFeeds.length && (
          <DiscoText variant={"body-xs-600"} className={classes.noFeeds}>
            {"No feeds found."}
          </DiscoText>
        )}
      </Popover>
    </div>
  )
  function handleClose() {
    setAnchorEl(null)
    setSearch("")
  }

  function filterBySearch(feed: { name: string }) {
    if (!search) return true
    const regex = new RegExp(search, "gi")
    return regex.test(feed.name)
  }
}

const useStyles = makeUseStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1),
  },
  noFeeds: {
    marginTop: theme.spacing(1),
  },
  popoverPaper: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(2),
    margin: 0,
    maxHeight: "500px",
    minWidth: "200px",
  },
  feedGroupHeader: {
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(0, 1, 0, 1),
  },
  feedsContainer: {
    marginLeft: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
    borderLeft: theme.palette.constants.borderSmall,
  },
}))

export default Relay.withSkeleton({
  component: FeedSelectorDropdown,
  skeleton: () => null,
})
