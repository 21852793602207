import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { StripeConnectionButtonDisconnectMutation } from "@/payment/stripe-integration/button/__generated__/StripeConnectionButtonDisconnectMutation.graphql"
import { StripeConnectionButtonQuery } from "@/payment/stripe-integration/button/__generated__/StripeConnectionButtonQuery.graphql"
import Relay from "@/relay/relayUtils"
import { useOrganizationRoleLabel } from "@/role/roleUtils"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoButtonSkeleton, DiscoTooltip } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { Link } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

interface StripeConnectionButtonProps extends TestIDProps {
  connectButtonText?: string
  disconnectButtonText?: string
  showConnectConfirmDialog?: boolean
}

function StripeConnectionButton({
  connectButtonText,
  disconnectButtonText,
  showConnectConfirmDialog,
  testid = "StripeConnectionButton",
}: StripeConnectionButtonProps) {
  const activeOrganization = useActiveOrganization()!
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const ownerLabel = useOrganizationRoleLabel("owner")

  const { organization } = useLazyLoadQuery<StripeConnectionButtonQuery>(
    graphql`
      query StripeConnectionButtonQuery($id: ID!, $returnTo: String!) {
        organization: node(id: $id) {
          ... on Organization {
            id
            stripeOauthAuthorizeUrl(returnTo: $returnTo)
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      returnTo: window.location.href,
    }
  )

  const disconnectStripeConnection =
    Relay.useAsyncMutation<StripeConnectionButtonDisconnectMutation>(graphql`
      mutation StripeConnectionButtonDisconnectMutation(
        $input: DisconnectStripeAccountInput!
        $returnTo: String!
      ) {
        disconnectStripeAccount(input: $input) {
          node {
            id
            hasStripeConnection
            stripeOauthAuthorizeUrl(returnTo: $returnTo)
          }
        }
      }
    `)

  const canManage = activeOrganization.viewerPermissions.has("stripe_integration.manage")
  const isAcacia = activeOrganization.checkoutVersion === "stripe_acacia"

  return (
    <>
      {activeOrganization.hasStripeConnection ? (
        <>
          <DiscoTooltip
            content={
              isAcacia
                ? `Cannot disconnect Stripe once configured. Contact us for support.`
                : `Only community ${ownerLabel.plural} can disconnect Stripe.`
            }
            disabled={canManage && !isAcacia}
          >
            <div>
              <DiscoButton
                data-testid={`${testid}.remove-stripe-connection-button`}
                shouldDisplaySpinner={isDeleting}
                color={"error"}
                onClick={removeStripeConnection}
                disabled={!canManage || isAcacia}
              >
                {disconnectButtonText || "Disconnect Stripe"}
              </DiscoButton>
            </div>
          </DiscoTooltip>

          <DiscoWarningModal
            testid={"DisconnectStripe"}
            isOpen={showDeleteModal}
            confirmationButtonProps={{
              onClick: handleDisconnectButtonClick,
              children: "Yes, disconnect Stripe",
            }}
            onClose={() => {
              setShowDeleteModal(false)
            }}
            modalContentLabel={"Disconnect Stripe"}
            title={"Disconnect Stripe?"}
            description={
              "Are you sure you want to disconnect Stripe from your community? You will not be able to collect payments in your community if you do this."
            }
          />
        </>
      ) : (
        <>
          <DiscoTooltip
            content={`Only community ${ownerLabel.plural} can connect Stripe`}
            disabled={canManage}
          >
            <div>
              <DiscoButton
                component={Link}
                testid={`${testid}.stripe-connection-button`}
                onClick={handleConnectButtonClick}
                disabled={!canManage}
              >
                {connectButtonText || "Connect Stripe"}
              </DiscoButton>
            </div>
          </DiscoTooltip>
          <DiscoWarningModal
            icon={"warning"}
            variant={"primary"}
            testid={"ConnectStripe"}
            isOpen={showConnectModal}
            confirmationButtonProps={{
              onClick: redirectToStripe,
              children: "Continue to Stripe",
            }}
            onClose={() => {
              setShowConnectModal(false)
            }}
            modalContentLabel={"Continue to Stripe"}
            title={"Continue to Stripe?"}
            description={
              "You'll be redirected to Stripe to continue with the connection process. Before continuing, please save any changes you've made."
            }
          />
        </>
      )}
    </>
  )

  function removeStripeConnection() {
    setShowDeleteModal(true)
  }

  function handleConnectButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (showConnectConfirmDialog) setShowConnectModal(true)
    else redirectToStripe(e)
  }

  function redirectToStripe(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    setShowConnectModal(false)
    window.open(organization!.stripeOauthAuthorizeUrl!, "_self")
  }

  async function handleDisconnectButtonClick() {
    setShowDeleteModal(false)
    setIsDeleting(true)
    try {
      await disconnectStripeConnection({
        input: {
          id: activeOrganization.id,
        },
        returnTo: window.location.href,
      })
    } catch (error) {
      displayErrorToast(error)
    }
    setIsDeleting(false)
  }
}

export default Relay.withSkeleton({
  component: StripeConnectionButton,
  skeleton: () => <DiscoButtonSkeleton width={"200px"} />,
})
